import React from 'react';

import Container from './container';
// import Grid from './grid';

import science from '../images/science-7c.png';

const HowItWorks = () => {
  const stepIconClassName = 'w-6 h-6';
  const steps = [
    {
      heading: 'Design',
      description: 'We take your branding and create an interactive design.',
      icon: (
        <svg className={stepIconClassName} fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
            clipRule="evenodd"
          ></path>
        </svg>
        // <svg className={stepIconClassName} fill="currentColor" viewBox="0 0 20 20">
        //   <path
        //     fillRule="evenodd"
        //     d="M11.757 2.034a1 1 0 01.638.519c.483.967.844 1.554 1.207 2.03.368.482.756.876 1.348 1.467A6.985 6.985 0 0117 11a7.002 7.002 0 01-14 0c0-1.79.684-3.583 2.05-4.95a1 1 0 011.707.707c0 1.12.07 1.973.398 2.654.18.374.461.74.945 1.067.116-1.061.328-2.354.614-3.58.225-.966.505-1.93.839-2.734.167-.403.356-.785.57-1.116.208-.322.476-.649.822-.88a1 1 0 01.812-.134zm.364 13.087A2.998 2.998 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879.586.585.879 1.353.879 2.121s-.293 1.536-.879 2.121z"
        //     clipRule="evenodd"
        //   ></path>
        // </svg>
      ),
    },
    {
      heading: 'Fine-tune',
      description: 'We make changes until you’re fully satisfied.',
      icon: (
        <svg className={stepIconClassName} fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
            clipRule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      heading: 'Launch',
      description: 'Once the design has been finalized, we launch your website!',
      icon: (
        <svg className={stepIconClassName} fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M11.757 2.034a1 1 0 01.638.519c.483.967.844 1.554 1.207 2.03.368.482.756.876 1.348 1.467A6.985 6.985 0 0117 11a7.002 7.002 0 01-14 0c0-1.79.684-3.583 2.05-4.95a1 1 0 011.707.707c0 1.12.07 1.973.398 2.654.18.374.461.74.945 1.067.116-1.061.328-2.354.614-3.58.225-.966.505-1.93.839-2.734.167-.403.356-.785.57-1.116.208-.322.476-.649.822-.88a1 1 0 01.812-.134zm.364 13.087A2.998 2.998 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879.586.585.879 1.353.879 2.121s-.293 1.536-.879 2.121z"
            clipRule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      heading: 'Enjoy',
      description: 'Sit back and enjoy as we fully manage your website.',
      icon: (
        // <svg className={stepIconClassName} fill="currentColor" viewBox="0 0 20 20">
        //   <path
        //     fillRule="evenodd"
        //     d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
        //     clipRule="evenodd"
        //   ></path>
        // </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={stepIconClassName}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M6 3a1 1 0 011-1h.01a1 1 0 010 2H7a1 1 0 01-1-1zm2 3a1 1 0 00-2 0v1a2 2 0 00-2 2v1a2 2 0 00-2 2v.683a3.7 3.7 0 011.055.485 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0A3.7 3.7 0 0118 12.683V12a2 2 0 00-2-2V9a2 2 0 00-2-2V6a1 1 0 10-2 0v1h-1V6a1 1 0 10-2 0v1H8V6zm10 8.868a3.704 3.704 0 01-4.055-.036 1.704 1.704 0 00-1.89 0 3.704 3.704 0 01-4.11 0 1.704 1.704 0 00-1.89 0A3.704 3.704 0 012 14.868V17a1 1 0 001 1h14a1 1 0 001-1v-2.132zM9 3a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm3 0a1 1 0 011-1h.01a1 1 0 110 2H13a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ];

  return (
    <>
      <section
        className="py-16 my-16 bg-center bg-repeat md:py-20 md:my-20 lg:py-24 lg:mt-24 xl:py-32"
        style={{
          // background: 'linear-gradient(43deg, #0669FF 0%, #805AD5 40%, #F6AD55 100%)', // decent?
          // background: 'linear-gradient(43deg, #c33764, #4C51BF)', // decent
          // background: 'linear-gradient(43deg, #2b44ff 20%, #ff4133 100%)', // good?
          // background: 'linear-gradient(43deg, #EC7965 0%, #4C51BF 100%)', // decent
          // background: 'linear-gradient(to right, #fcac56 0%, #c12a6c 100%)', // decent
          // background:
          //   'linear-gradient(43deg, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%)', // good
          // background: 'linear-gradient(to right, #fc5c7d, #6a82fb)', // decent - too pink
          // background: 'linear-gradient(to right, #fc466b, #3f5efb)', // a little too pink
          // background: 'linear-gradient(43deg, #9013fe, #3f5efb)',
          // background: 'linear-gradient(90deg, #ff715b, #5132c0)',
          // backgroundColor: '#f5f6f8',
          backgroundImage: `url(${science})`,
          // blue 0669ff
        }}
      >
        <Container>
          <h2 className="text-4xl font-bold leading-tight tracking-tighter text-center text-black xl:text-4xl">
            We make having a website easy
          </h2>

          <div className="grid mt-16 text-lg gap-y-12 md:gap-x-8 md:grid-cols-2 xl:grid-cols-4">
            {steps.map((step) => (
              <div key={step.heading}>
                <div className="flex items-center justify-center w-12 h-12 mx-auto text-white bg-blue-500 rounded-md">
                  {step.icon}
                </div>

                <h3 className="mt-3 text-2xl font-bold tracking-tight text-center">
                  {step.heading}
                </h3>
                <div className="max-w-sm mx-auto text-lg text-center text-gray-700">
                  {step.description}
                </div>
              </div>
            ))}
          </div>
        </Container>

        {/* <div
          class="absolute bottom-0 left-0 overflow-hidden w-full"
          style={{ lineHeight: '0', transform: 'rotate(180deg)' }}
        >
          <svg
            className="relative block"
            style={{ width: 'calc(100% + 1.3px)', height: '100px', transform: 'rotateY(180deg)' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" fill="#ffffff"></path>
          </svg>
        </div> */}
      </section>
    </>
  );
};

export default HowItWorks;

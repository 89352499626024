import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../layout';
import SEO from '../components/seo';

import Container from '../components/container';
// import Grid from '../components/grid';

// import Image from "../components/image";
import Hero from '../components/hero';
import HowItWorks from '../components/howItWorks';
import Benefits from '../components/benefits';
import Pricing from '../components/pricing';
import FAQs from '../components/faqs';

// import stock from '../images/stock-01.jpg';

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        description={`Forget about managing your own website. Nolan Oak provides a fully managed website so you can spend more time doing what you do best!`}
      />

      <Hero />
      <HowItWorks />
      <Benefits />

      <section className="py-16 my-12 md:py-24 xl:py-32 lg:my-24 bg-orange-150">
        <Container>
          <h1 className="text-4xl font-black leading-none tracking-tight text-center xl:text-5xl">
            Choose your plan
          </h1>

          <Pricing benefitsPosition="above" />
        </Container>
      </section>

      {/* <Content /> */}
      <FAQs />
    </Layout>
  );
};

export default IndexPage;

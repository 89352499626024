import React from 'react';
import { Link } from 'gatsby';

import Container from './container';
import Grid from './grid';

import Scene from '../images/devices-d.inline.svg';

const Hero = () => {
  return (
    <Container className="my-8 md:my-12 lg:my-24">
      <Grid>
        <div
          className="flex flex-col justify-center col-span-12 lg:col-span-6 xl:col-span-5"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <h1 className="text-4xl font-bold tracking-tighter leading-tighter xl:text-5xl">
            Forget about managing your own website. We handle everything for you!
          </h1>

          <p className="mt-6 text-xl text-gray-700">
            Nolan Oak provides a fully managed website so you can spend more time doing what you do
            best!
          </p>

          <div className="mt-6">
            <Link
              to="/pricing#launch"
              className="inline-flex px-5 py-3 text-lg font-semibold text-white bg-blue-500 rounded-md hover:bg-opacity-75"
            >
              Launch your website
            </Link>
          </div>
        </div>

        <div className="flex items-center justify-center col-span-12 mt-8 lg:mt-0 lg:grid-col-7/6">
          <Scene className="w-full h-auto" />
        </div>
      </Grid>
    </Container>
  );
};

export default Hero;

import React from 'react';
import { Link } from 'gatsby';

import Container from './container';

const FAQs = () => {
  const faqs = [
    {
      question: `Why do I need a website?`,
      answer: `Having a website will help you attract new customers; make you more money; build credibility; save you time; and maintain your brand.`,
    },
    {
      question: `How long before my website is launched?`,
      answer: `As long as you're able to provide all necessary content, we can have your website up in just a few weeks!`,
    },
    {
      question: `Are there really unlimited changes?`,
      answer: `Absolutely! Want to change the content on a page? Want to add a new service you offer? Want to update part of the design? Send us a message and we'll use our expertise to make the changes for you!`,
    },
    {
      question: `How long does it take for an update to be made?`,
      answer: `We do our best to update your website as fast as possible! That means your change will be made within two business days—often much sooner.`,
    },
    {
      question: `What if I want to change my plan?`,
      answer: `We make it very easy. Contact us anytime to change your plan to better suit your needs as your business grows.`,
    },
    {
      question: `What if I want to cancel my plan?`,
      answer: `Not a problem! Since our service is month-to-month, there are no obligations!`,
    },
    {
      question: `What if I already have a design I want to use?`,
      answer: `Please contact us to discuss how we can turn your design into a website!`,
    },
    {
      question: `Do you offer any other services?`,
      answer: `We most certainly do! In addition to websites, we also build web applications. Please contact us for more details.`,
    },
  ];

  return (
    <>
      <Container className="my-12 md:my-24">
        <h1
          className="text-3xl font-black leading-tight text-center xl:text-4xl"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Frequently asked questions
        </h1>

        <div className="grid gap-6 mt-12 md:mt-16 md:gap-8 md:grid-cols-2">
          {faqs.map((faq) => (
            <div key={faq.question} data-aos="fade-up" data-aos-duration="500">
              <div className="flex items-center justify-between">
                <div className="text-lg font-semibold">{faq.question}</div>
              </div>

              <div className="mt-1 text-lg text-gray-700 md:mt-2">{faq.answer}</div>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-8 sm:mt-12 md:mt-16">
          <Link
            to="/contact-us"
            className="inline-flex px-5 py-3 text-lg font-semibold text-white bg-blue-500 rounded-md hover:bg-opacity-75"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Ask us a question!
          </Link>
        </div>
      </Container>
    </>
  );
};

export default FAQs;

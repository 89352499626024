import React from 'react';

import Container from './container';
import Grid from './grid';

const Benefits = () => {
  const benefits = [
    {
      heading: 'No setup fee',
      description: 'Get started today without spending thousands.',
    },
    {
      heading: 'Fixed monthly rate',
      description: 'Know what to expect each month with no hidden costs!',
    },
    {
      heading: 'Fully managed',
      description: 'We handle every aspect of your website. No more time spent fumbling around.',
    },
    {
      heading: 'Unlimited changes',
      description: 'You never have to worry about updating your website again!',
    },
    {
      heading: 'Design and development',
      description: 'We custom-build every aspect of your website to best suit your needs.',
    },
    // {
    //   heading: 'Unlimited bandwidth and storage',
    //   description: 'No data restrictions on your website, no matter how big or small.',
    // },
    // {
    //   heading: 'Free custom domain*',
    //   description: 'This is a description that needs to be changed',
    // },
    {
      heading: 'Free hosting',
      description: 'No additional charge for hosting. Everything you need is included!',
    },
    {
      heading: 'Free backups',
      description: 'No more worrying about losing data. Your website is backed-up daily.',
    },
    {
      heading: 'SSL security',
      description: 'Know your website is safe and secure with an SSL certificate.',
    },
    {
      heading: 'SEO for site visibility',
      description:
        'We increase traffic to your website by optimizing for search engine visibility.',
    },
    {
      heading: 'Mobile-optimized',
      description: 'We design your website to look great on all devices!',
    },
    {
      heading: 'Fully accessible',
      description: 'We use accessibility best practices for full AODA compliance.',
    },
    {
      heading: 'Cancel anytime',
      description: 'No longer need your website? No problem! Cancel anytime, hassle-free.',
    },
  ];

  return (
    <Container className="my-16 md:my-20 lg:my-32">
      <Grid>
        <div className="col-span-12 lg:col-span-3">
          <h1
            className="max-w-md mx-auto text-3xl font-bold leading-tight tracking-tighter text-center lg:text-left lg:max-w-none xl:text-4xl"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Benefits you'll love included in every plan
          </h1>
        </div>

        <div className="grid col-span-12 mt-10 gap-y-6 md:gap-x-6 md:gap-y-10 md:grid-cols-2 lg:mt-0 lg:grid-col-5/8">
          {benefits.map((benefit) => (
            <div key={benefit.heading} className="flex" data-aos="fade-up" data-aos-duration="500">
              <div>
                <svg
                  fill="currentColor"
                  className="w-6 h-6 text-green-600 mt-0125"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>

              <div className="ml-2">
                <h2 className="flex items-center text-xl font-bold">{benefit.heading}</h2>
                <p className="mt-1 text-gray-800">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Container>
  );
};

export default Benefits;

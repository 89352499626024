import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import getStripe from '../utils/stripejs';

// import Container from './container';

const Pricing = ({ benefitsPosition }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  const [loading, setLoading] = useState(false);

  const plans = [
    {
      name: 'Basic',
      price: 199,
      description: 'Ideal for businesses that are just starting out or need a basic website.',
      features: ['Includes up to 5 pages'],
      priceId: 'price_1HK4ihL5Sxh8DhYi8SE87QnL',
    },
    {
      featured: true,
      name: 'Professional',
      price: 299,
      originalPrice: 399,
      description: 'Great for businesses that want a professional web presence.',
      features: [
        'Includes up to 10 pages',
        'Google Analytics',
        'Forms (e.g., contact, application)',
        'Newsletter sign-up',
      ],
      priceId: 'price_1HK4lQL5Sxh8DhYinkLdUeFk',
    },
    {
      name: 'Unlimited',
      price: 399,
      originalPrice: 499,
      description: 'Perfect for businesses that rely on the web to promote their business.',
      features: ['Unlimited pages', 'Advanced custom components', 'Blog'],
      priceId: 'price_1HK4mFL5Sxh8DhYi2YpvyXWl',
    },
  ];

  const handlePurchaseClick = async (e, priceId) => {
    e.preventDefault();
    setLoading(true);
    document.body.classList.add('overflow-hidden');

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `${site.siteMetadata.siteUrl}/thank-you`,
      cancelUrl: `${site.siteMetadata.siteUrl}/pricing`,
    });

    // TODO
    if (error) {
      setLoading(false);
      document.body.classList.remove('overflow-hidden');
      // console.log(error);
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-screen h-screen p-4 bg-gray-900 bg-opacity-75">
          <div className="w-full max-w-xl p-6 bg-white rounded-md shadow-md">
            <h1 className="text-2xl font-black tracking-tight xl:text-3xl">Thank You!</h1>
            <p className="mt-2 text-lg text-gray-700">
              We're taking you to Stripe to set up your subscription.
            </p>
            <p className="mt-2 text-lg text-gray-700">Please hold tight!</p>
          </div>
        </div>
      )}

      <div className="grid gap-12 mt-12 lg:mt-20 lg:gap-0 lg:grid-cols-3">
        {plans.map((plan, index) => (
          <div
            key={plan.name}
            className={
              'max-w-lg mx-auto flex flex-col w-full relative bg-white lg:max-w-none lg:mx-auto' +
              (plan.featured ? ' border-2 border-blue-500' : ' border-t-2 border-transparent')
            }
          >
            {plan.featured && (
              <div className="absolute inset-x-0 top-0 flex justify-center -mt-4">
                <div className="inline-flex items-center h-8 px-3 py-1 text-sm font-semibold text-white bg-blue-500 rounded-full">
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    ></path>
                  </svg>

                  <div className="ml-2 mr-1">Most popular!</div>
                </div>
              </div>
            )}

            <div className="p-8 border-b-2 lg:p-6 xl:p-8 border-orange-150">
              <h2 className="text-3xl font-black">{plan.name}</h2>

              <div className="flex items-center mt-2">
                {plan.originalPrice && (
                  <div className="mr-2 text-2xl font-bold text-gray-700 line-through">
                    ${plan.originalPrice}
                  </div>
                )}
                <div className="text-5xl font-black leading-none">${plan.price}</div>
                <div className="ml-2 font-semibold text-gray-700">CAD/mo</div>
              </div>

              <div className="mt-4 text-gray-800">{plan.description}</div>
            </div>

            <div className="flex flex-col h-full p-8 lg:p-6 xl:p-8">
              <h3 className="text-sm font-bold tracking-wide">WHAT'S INCLUDED</h3>

              <ul className="mt-4 mb-8 space-y-3 text-gray-700">
                <li className="flex">
                  <svg fill="currentColor" className="w-6 h-6 text-green-600" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>

                  <div className="ml-2 font-bold">
                    {index === 0
                      ? `All the benefits you'll love (${benefitsPosition})`
                      : `All the ${plans[index - 1].name} plan features`}
                  </div>
                </li>

                {plan.features.map((feature) => (
                  <li key={feature} className="flex">
                    <svg fill="currentColor" className="w-6 h-6 text-green-600" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>

                    <div className="ml-2">{feature}</div>
                  </li>
                ))}
              </ul>

              <div className="flex mt-auto">
                <button
                  type="button"
                  className={
                    'w-full px-5 py-3 font-semibold rounded-md ' +
                    (plan.featured
                      ? ' bg-blue-500 text-white hover:bg-blue-600'
                      : ' border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white')
                  }
                  onClick={(e) => handlePurchaseClick(e, plan.priceId)}
                >
                  Launch your website
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Pricing;
